.scroll {
  /* overflow-y: scroll;
  height: 70vh  ; */
  margin-bottom: 150px;
}

.container {
  margin: 5px;
  padding: 10px;
  border-radius: 3px;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.1);
}

.main {
  display: flex;
  flex-direction: column;
  gap: 5px;
  font-size: 1rem;
}

.flexBox {
  display: flex;
  justify-content: space-between;
}

.subContainer {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin: 5px 20px;
  color: gray;
  font-size: 12px;
}

.gap {
  display: flex;
  gap: 3px;
}

.rising {
  color: green; /* Green color for rising */
}

.falling {
  color: red; /* Red color for falling */
}

.buttonCenter {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.closePosition {
  border: none;
  border-radius: 2px;
  padding: 5px;
  font-size: 14px;
  color: red;
}

.error {
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: red;
}

.modifyPosition {
  color: green;
}

.modifyForm {
  position: fixed;
  padding-top: 30px;
  background-color: rgba(0, 0, 0, 0.563);
  height: 100%;
  width: 100%;
  top: 9%;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.centerDiv {
  display: flex;
  justify-content: center;
  align-items: center;
}

.tabContainer {
  display: flex;
  justify-content: center;
  padding: 10px;
  font-weight: 600;
  gap: 10px;
  font-size: 16px;
}

.tabs {
  width: max-content;
  padding: 10px 30px;
  border-radius: 8px;
  background-color: rgb(237, 236, 236);
}

.tabs span {
  cursor: pointer;
  padding: 3px 10px;
  border-bottom: 2px solid transparent;
  transition: border-bottom-color 0.3s ease;
}

.tabClicked {
  background-color: #00c3ffc2;
  border-radius: 8px;
  animation: tabClickAnimation 0.5s ease;
  color: white;
}

.sortAndFilter {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
  justify-content: space-between;
  padding: 0 5px;
  border-radius: 8px; /* Rounded corners */
}

.sortAndFilter select,
.sortAndFilter input[type="date"] {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  color: #333;
  outline: none;
}

.sortAndFilter select:focus,
.sortAndFilter input[type="date"]:focus {
  border-color: #007bff; /* Blue border color when focused */
}

.error {
  font-size: 1.2rem; /* Increase font size for better readability */
  display: flex;
  align-items: center;
  justify-content: center;
  color: #f44336; /* Use a more modern red color */
  text-align: center; /* Center text horizontally */
  padding-top: 10%;
}

.iconWithText {
  display: flex;
  justify-content: center;
  align-items: center;
}
