@tailwind base;
@tailwind components;
@tailwind utilities;

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}



body {
  overflow: none; /* Enable scrolling */
}

body::-webkit-scrollbar {
  display: none;
}
