.scrollTable {
  max-height: inherit;
  height: inherit;
  overflow: auto;
  scrollbar-width: thin;
  background-color: white;
}

.table {
  width: 100%;
  border-collapse: collapse;
  margin-left: 5px;
  margin-bottom: 60px;
}

.table th,
.table td {
  padding: 8px;
  text-align: left;
  border: none;
  font-size: 10px;
}

.table th {
  position: sticky;
  top: 0;
  color: #333;
  background-color: white;
}

.redCross {
  color: red;
  cursor: pointer;
}

.redCross:active {
  color: green;
}

.tabContainer {
  display: flex;
  justify-content: center;
  padding: 10px;
  font-weight: 600;
  gap: 10px;
  font-size: 14px;
}

.tabs {
  width: max-content;
  padding: 10px 5px;
  border-radius: 8px;
  background-color: rgb(237, 236, 236);
}

.tabs span {
  cursor: pointer;
  padding: 5px 10px;
  border-bottom: 2px solid transparent;
  transition: border-bottom-color 0.3s ease;
}

@keyframes tabClickAnimation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.tabClicked {
  background-color: #00c3ffc2;
  border-radius: 8px;
  animation: tabClickAnimation 0.5s ease;
  color: white;
}

.card {
  border-radius: 3px;
  margin: 6px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  font-size: 12px;
  background-color: rgb(0, 0, 0);
  padding: 10px;
  gap: 5px;
  color: white;
}

.card:last-child {
  margin-bottom: 100px;
}

.field {
  font-weight: 500;
}

.mrow {
  display: flex;
  justify-content: space-between;
}

.mobileView {
  display: none;
}

.modifyOverlay {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
}

.sortAndFilter {
  display: flex;
  align-items: center; /* Align items vertically */
  gap: 10px; /* Add space between elements */
  border-top: 2px solid #f2f2f2;
  justify-content: space-between;
  padding: 5px 40px 5px 10px;
  background-color: white;
}

.sortAndFilter select,
.sortAndFilter input[type="date"] {
  padding: 2px;
  border: 1px solid #f1f1f1;
  border-radius: 2px;
  height: fit-content;
}

.sticky {
  cursor: grabbing; /* Change cursor to grabbing icon when dragging */
  position: sticky;
  top: 0; /* Stick to the top of the viewport */
  z-index: 999; 
}

@media screen and (max-width: 768px) {
  .scrollTable {
    display: none;
  }

  .mobileView {
    display: block;
  }
}
